import React from 'react'
import { Box, Text } from 'rebass/styled-components'
import styled from 'styled-components'

const Row = styled(Box)`
    height:'50px';
    margin: 0 ${(props) => props.theme.space.md};
    .hr{
        border: 1px solid ${(props) => props.theme.colors.greyLight};
        border-right: 0px;
        border-left: 0px;
        border-top: 0px;
    }

`

const DividedRow = ({ children }) => {
    return (
        <Row>
            <hr className='hr'/>
            <Text as='p' variant='body'>
                {children}
            </Text>
        </Row>
    );
};

export default DividedRow;