import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, Text } from 'rebass/styled-components'
import { Link } from 'gatsby'
import { getClients } from '../../models/Clients'
import Loading from '../general/Loading'
import DividedRow from './DividedRow'


const ClientsPanel = ({ dispatch }) => {
    const [loading, setLoading] = useState(false)
    const [clients, setClients] = useState(false)

    useEffect(() => {
        let isCancelled = false
        setLoading(true)
        const fetchData = async () => {
            const params = {
                order: ['date_modified', 'desc'],
                limitNumber: 12,
            }
            const results = await getClients(params)
            if (!isCancelled) {
                const items = []
                if (results.list) {
                    Object.entries(results.list).map(([key, item]) => {
                        items.push(item)
                        return true
                    })
                    setClients(items)
                    setLoading(false)
                }
            }
        }
        fetchData()

        return () => {
            isCancelled = true
        }
    }, [])
    return (
        <>
            <Box color='greyDark' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Text ml='md' mb='md' as='h4' variant='h4'>
                    Recently Updated Clients
                </Text>
            </Box>
            <Box>
                <Box height='40px'/>
                {
                    loading ? 
                        <Box p='xs'><Loading /></Box> :
                        clients &&
                        Object.entries(clients).map(([key, item]) => {
                            return <DividedRow key={item.id}>
                                <Link to={`/clients/edit/${item.id}`}>
                                    {item.first_name + ' ' + item.last_name} 
                                </Link>
                            </DividedRow>
                        })
                }
                <DividedRow mt='xxl' />
            </Box>
        </>
    )
}
function mapStateToProps(state) {
    return {
        list: state.List.list,
    };
}
export default connect(mapStateToProps)(ClientsPanel)