import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Box, Text } from 'rebass/styled-components'
import { getRecentLeads } from '../../models/Leads'
import Loading from '../general/Loading'
import DividedRow from './DividedRow'

const LeadsPanel = () => {
    const [loading, setLoading] = useState(false)
    const [leads, setLeads] = useState(false)

    // getting the leads
    useEffect(() => {
        let isCancelled = false
        setLoading(true)

        getRecentLeads(12).then(response => {
            if (!isCancelled) {
                if (response.hits && response.hits.length) {
                    setLeads(response.hits)
                }
                setLoading(false)
            }
        })

        return () => {
            isCancelled = true
        }
    }, [])

    return (
        <>
            <Box color='greyDark' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Text ml='md' mb='md' as='h4' variant='h4'>
                    Recent Leads
                </Text>
            </Box>
            <Box>
                <Box height='40px'/>
                {
                    loading ? 
                        <Box p='xs'><Loading /></Box> :
                        leads &&
                        Object.entries(leads).map(([key, item]) => {
                            const date = new Date(item.date_added)
                            return <DividedRow key={item.objectID}>
                                <Link to={`/leads/edit/${item.objectID}`}>
                                    {`${item.title} - ${item.type === 'selling' ? (item.ownerName || "Not Known") : (item.buyerName || "Not Known")} - ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} - ${(item.type === 'buying' ? 'Buying' : 'Selling')}`}
                                </Link>
                            </DividedRow>
                        })
                }
                <DividedRow mt='xxl' />
            </Box>
        </>
    )
}

export default LeadsPanel