import React, { useEffect, useState } from 'react'
import { Box, Flex, Text } from 'rebass/styled-components'
import { Link } from 'gatsby'
import CollectionCard from '../cards/CollectionCard'
import { getCollectionList } from '../../helpers/ListHandler'
import Loading from '../general/Loading'

const AvailablePanel = () => {
    const [loading, setLoading] = useState(false)
    const [list, setList] = useState(false)
    useEffect(() => {
        let isCancelled = false
        setLoading(true)

        const fetchData = async () => {
            const results = await getCollectionList({ category: ['available'], perPage: 10, sortingReplica: 'date_added-desc' })
            if (!isCancelled) {
                const items = []
                if (results.list) {
                    Object.entries(results.list).map(([key, item]) => {
                        items.push(item)
                        return true
                    })
                    setList(items)
                    setLoading(false)
                }
            }
        }
        fetchData()

        return () => {
            isCancelled = true
        }
    }, [])

    return (<>
        <Box>
            <Text color='greyDark' ml='md' mb='md' variant='h4' as='h4'>
                <Link to='/collection/available/'>Available Vehicles - Recent</Link>
            </Text>
        </Box>
        {
            loading ? 
                <Flex p='xs' alignItems='center' justifyContent='center' width='100%'><Loading /></Flex> :
                <Box px="xs" pb='xs' sx={{
                    display: 'grid',
                    gridGap: 'md',
                    gridTemplateColumns: ['1fr', '1fr', 'repeat(1, 1fr)', 'repeat(2, 1fr)'],
                }}>
                    {
                        list &&
                        Object.entries(list).map(([key, item]) => {
                            return <CollectionCard options={item} key={item.id} code={false}/>
                        })
                    }
                </Box>
        }
        <Flex justifyContent='center' mt='sm'>
            <Link  to='/collection/available/'>
                <Text color='greyDark' sx={{ textDecoration: 'underline' }}> View All</Text>
            </Link>
        </Flex>
    </>
    )
}

export default AvailablePanel