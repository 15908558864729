import React, { useEffect, useState } from 'react'
import { Box, Text } from 'rebass/styled-components'
import { Link } from 'gatsby'
import { getCollectionList } from '../../helpers/ListHandler'
import Loading from '../general/Loading'
import DividedRow from './DividedRow'

const VehiclesPanel = () => {
    const [loading, setLoading] = useState(false)
    const [vehicles, setVehicles] = useState(false)

    useEffect(() => {
        let isCancelled = false
        setLoading(true)
        const fetchData = async () => {
            const params = {
                perPage: 12,
            }
            const results = await getCollectionList(params)
            if (!isCancelled) {
                const items = []
                if (results.list) {
                    Object.entries(results.list).map(([key, item]) => {
                        items.push(item)
                        return true
                    })
                    setVehicles(items)
                    setLoading(false)
                }
            }
        }
        fetchData()

        return () => {
            isCancelled = true
        }
    }, [])
    return (
        <>
            <Box color='greyDark' sx={{
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'greyLight',
                borderTop: 0,
                borderLeft: 0,
                borderRight: 0,
            }}>
                <Text ml='md' mb='md' as='h4' variant='h4'>
                    Recently Updated Items
                </Text>
            </Box>
            <Box>
                <Box height='40px'/>
                {
                    loading ? 
                        <Box p='xs'><Loading /></Box> :
                        vehicles &&
                        Object.entries(vehicles).map(([key, item]) => {
                            return <DividedRow key={item.id}>
                                <Link to={`/collection/edit/${item.id}`}>
                                    {item.title }
                                </Link>
                            </DividedRow>
                        })
                }
                <DividedRow mt='xxl' />
            </Box>
        </>
    )
}

export default VehiclesPanel