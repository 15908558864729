import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Button, Flex } from 'rebass/styled-components'
import { generateSignature, showCloudinaryWidget } from '../../helpers/Cloudinary'
import styled from 'styled-components'
import MediaRender from '../general/MediaRender'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { onGalleryChange } from '../../helpers/Media'
import MediaModal from './MediaModal'


const ImageBox = styled(Box)`
    opacity: ${(props) => props.faded ? '0.2' : '1'};
    transform-origin: 0 0;
    position: relative;
    ${(props) => props.style}
`

const Trash = styled(Box)`
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSizes.sm};
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 1;
    &:hover {
        color: ${(props) => props.theme.colors.red};
    }
`

const FeaturedImage = ({ dispatch, signature, showButtons=true, mediaSet, file }) => {
    const [media, setMedia] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)

    useEffect(() => {
        if (!signature) {
            generateSignature(dispatch)
        }
    }, [signature])

    useEffect(() => {
        if  (file) {
            setMedia(file)
        } else if(mediaSet && mediaSet.length) {
            setMedia(mediaSet[0])
        }
        else {
            setMedia(false)
        }
    }, [mediaSet, file])

    const addMediaFromGallery = (file) => {
        onGalleryChange(dispatch, [file])
        setModalIsOpen(false)
    }

    const showWidget = () => {
        showCloudinaryWidget(signature, (files) => {
            onGalleryChange(dispatch, files.length ? [files.shift()] : [])
        })
    }

    const remove = (index) => {
        onGalleryChange(dispatch, [])
    }

    return (
        <>
            <ImageBox>
                {
                    media ?
                        <>
                            {
                                showButtons ?
                                    <Trash onClick={remove}><FontAwesomeIcon icon={faTrashAlt} /></Trash> :
                                    null
                            }
                            <MediaRender file={media} />
                        </> :
                        <Flex bg='greyLight' justifyContent='center' alignItems='center' width='100%' height='100%' minHeight='182px'>
                            No Image
                        </Flex>
                }
            </ImageBox>
            {
                showButtons ?
                    <Flex pt='sm'>
                        <Box mr='xxs'>
                            <Button type="button" variant='primarySmall'  onClick={() => setModalIsOpen(true)} className="upload-button">
                                { media ? 'Replace Featured Image from Gallery' : 'Add Featured Image from Gallery' }
                            </Button>
                            <Button mt="xxs" type="button" variant='primarySmall' onClick={showWidget} className="upload-button">
                                { media ? 'Replace Featured Image (New Image)' : 'Add Featured Image (New Image)' }
                            </Button>
                        </Box>
                    </Flex> :
                    null
            }
            { modalIsOpen ? <MediaModal setModalIsOpen={setModalIsOpen} addMediaFromGallery={addMediaFromGallery} /> : null }
        </>
    )
}

function mapStateToProps(state) {
    return {
        signature: state.Cloudinary && state.Cloudinary.signature,
        mediaSet: state.Pagebuilder.media.mediaSet,
    }
}
export default connect(mapStateToProps)(FeaturedImage)
