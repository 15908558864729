
import React from 'react'
import { DashboardSection } from '../../system/settings/pageData'
import Layout from '../../views/templates/adminLayout'
import DashboardView from '../../views/dashboard/index'

const PageInfo = {
    page: DashboardSection.section,
    icon: DashboardSection.icon,
}
const Dashboard = () => {
    return (
        <Layout PageInfo={PageInfo} minimumRole={DashboardSection.minimumRole}>
            <DashboardView />
        </Layout>
    )
}

export default Dashboard
