import React from 'react'
import { Box, Flex } from 'rebass/styled-components'
import { connect } from 'react-redux'
import AvailablePanel from '../../components/dashboard/AvailablePanel'
import LeadsPanel from '../../components/dashboard/LeadsPanel'
import ClientsPanel from '../../components/dashboard/ClientsPanel'
import VehiclesPanel from '../../components/dashboard/VehiclesPanel'
import PermissionWrapper from '../../hooks/PermissionWrapper'

const DashboardView = ({ user }) => {
    return (
        <Box variant="card" pt="sm" px='sm' mt="sm">
            <Flex mb='xs' pb='md' justifyContents='space-between'>
                <Box py='md' mr='xs' width='50%' sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'greyLight',
                }}>
                    <AvailablePanel />
                </Box>
                <Box py='md' ml='xs' width='50%' sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'greyLight',
                }}>
                    <PermissionWrapper tierId='areaLeads'>
                        <Box>
                            <LeadsPanel />
                        </Box>
                    </PermissionWrapper>
                    <Box mt='md'>
                        <VehiclesPanel />
                    </Box>
                    <Box mt='md'>
                        <ClientsPanel />
                    </Box>
                </Box>
            </Flex>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        user: state.Auth.user,
    }
}
export default connect(mapStateToProps)(DashboardView)
